import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Event,
  EventCategory,
  AllEvents,
  EventSport,
  EventTopCategory,
  EventType, SearchResult,
} from '@scpc/modules/sports/dto';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SportsService {

  constructor(private readonly http: HttpClient) {
  }

  public getSports(): Observable<EventSport[]> {
    const url = `/br/api/sports`;
    return this.http.get<{ sports: EventSport[] }>(url)
      .pipe(map((response: { sports: EventSport[] }) => response.sports.map(((sport: EventSport): EventSport => {
        if (sport.id === 'all_live') {
          sport.slug = 'live';
        }
        return sport;
      }))));
  }

  public getLiveSports(top: boolean): Observable<EventSport[]> {
    return this.http.get<{ sports: EventSport[] }>('/br/api/filters/sports' + (top ? '?topEvents=true' : ''))
      .pipe(map((data: { sports: EventSport[] }) => data.sports));
  }

  public getTournaments(type: EventType, sport: string, range: number): Observable<[EventTopCategory[], EventCategory[], EventSport | undefined]> {
    return this.http.get<{
      sport?: EventSport,
      categories: EventCategory[],
      topTournaments: EventTopCategory[]
    }>(`/br/api/filters/sports/${sport}/categories?live=${type === 'live'}` + (range ? `&period=${range}` : ''))
      .pipe(map(data => [data.topTournaments || [], data.categories || [], data.sport || undefined]));
  }

  public getTournamentsByEvent(slug: string): Observable<[EventTopCategory[], EventCategory[], EventSport[], number]> {
    return this.http.get<{
      categories: EventCategory[],
      topTournaments: EventTopCategory[],
      sports: EventSport[],
      liveEventsCount: number
    }>(`/br/api/filters/sports/any/categories?eventSlug=${encodeURIComponent(slug)}`)
      .pipe(map(data => [data.topTournaments || [], data.categories || [], data.sports || [], data.liveEventsCount || 0]));
  }

  public getInitialEvents(type: EventType,
                          sport?: string,
                          tournaments?: string[],
                          range?: number,
                          top?: boolean,
                          limit: number = 30): Observable<AllEvents> {
    let query: string = top ? `topEvents=true` : `live=${type === 'live'}`;
    if (limit) {
      query += `&limit=${limit}`;
    }
    if (range) {
      query += `&period=${range}`;
    }
    if (tournaments?.length) {
      query += `&tournaments=${tournaments.join(',')}`;
    } else if (sport && sport !== 'live') {
      query += `&sports=${sport}`;
    }
    return this.http.get<AllEvents>('/br/api/events-meta?' + query);
  }

  public getEvents(ids: string[], slug?: string, markets: 'top' | 'all' | 'main' | 'no_markets' = 'top', statistic: boolean = false): Observable<Event[]> {
    let query = `markets=${markets}&statistic=${statistic}&`;
    if (slug) {
      query += `slug=${encodeURIComponent(slug)}`;
    } else {
      query += `ids=${ids.join(',')}`;
    }
    return this.http.get<Event[]>('/br/api/events?' + query);
  }

  public search(query: string): Observable<SearchResult | null> {
    return this.http.get<SearchResult>(`/br/api/events-search?query=${query}`)
      .pipe(
        map((result: SearchResult): SearchResult | null => {
          if (!result.events && !result.tournaments && !result.sports) {
            return null;
          }
          return result;
        }),
        catchError(/* istanbul ignore next */ () => of(null)),
      );
  }

  public getStream(id: string): Observable<{ url: string }> {
    return this.http.get<{ url: string }>('/br/api/stream?eventId=' + id);
  }

}
