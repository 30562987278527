<div class='scp-search'>
  <button (click)='show()' [style.display]='clear  ? "none" : "flex"' aria-label='Search'>
    <img src='/assets/images/svg/search-2.svg' alt='' width='18' height='18'>
  </button>
  <div class='scp-search-block'
       [ngStyle]="{ 'pointer-events': visible  ? 'all' : 'none' }"
       #originRef
       cdkOverlayOrigin>
    <div class='input' [@inputInOut]="visible ? 'in' : 'out'">
      <input type='text' #inputRef [formControl]='searchControl' aria-label='Search input'>
      @if (clear) {
        <button (mousedown)='searchControl.setValue(""); inputRef.focus(); $event.preventDefault()'>
          <img src='/assets/images/svg/x-2.svg' alt='' width='34' height='34'>
        </button>
      }
    </div>
  </div>
</div>
<ng-template #overlayRef
             cdkConnectedOverlay
             [cdkConnectedOverlayPanelClass]='"scp-search-results-overlay"'
             [cdkConnectedOverlayPositions]='position'
             [cdkConnectedOverlayLockPosition]='false'
             [cdkConnectedOverlayWidth]='width'
             [cdkConnectedOverlayOrigin]='originRef'
             [cdkConnectedOverlayOpen]='clear'
             (backdropClick)='hide()'>
  <div class='scp-search-results' #contentRef (scroll)='onScroll()' (touchmove)='onScroll($event)'>
    @if (loading) {
      <div class='prompt loading'>
        <div class='ssc-square'></div>
        <div class='ssc-square'></div>
        <div class='ssc-square'></div>
      </div>
    } @else {
      @if (!result && !resultsItems?.length) {
        <div class='prompt'>
          @if (less) {
            <img src='/assets/images/svg/info-6.svg' alt='' height='54' width='54'>
            <div>{{ "Please enter more than two letters" | translate }}</div>
          } @else {
            <img src='/assets/images/svg/no-results-2.svg' alt='' height='54' width='54'>
            <div>{{ "Sorry! No result found :(" | translate }}</div>
          }
        </div>
      } @else if (resultsItems?.length) {
        <div>
          @for (result of resultsItems; track (result.slot || result.game)?.gameId || result.draw.drawId) {
            @if (result.slot || result.game) {
              <a class='game'
                 (click)='sendDataToAnalytics("game", true)'
                 [routerLink]='(result | formatGameUrl: activatedRoute).routerLink'
                 [queryParams]='(result | formatGameUrl: activatedRoute).queryParams'
                 [state]='{game: result.game || result.slot}'>
                <div>
                  @if (result.game || result.slot) {
                    <img
                      src='/assets/images/providers/search/{{(result.game || result.slot).provider.type.toLowerCase()}}.svg'
                      alt=''
                      height='30'
                      loading='lazy' />
                  }
                </div>
                {{ result.name }}
                <img loading='lazy' alt='' src='/assets/images/svg/arrow-2.svg' height='12' width='8' />
              </a>
            }
            @if (!result.slot && !result.game) {
              <a class='game'
                 (click)='sendDataToAnalytics("draw", true)'
                 routerLink='/lucky-numbers/{{result.draw.drawId}}'>
                <div class='country-flag'>
                  @if (result.draw) {
                    <img
                      src='/assets/common/lucky-numbers/flags/1x1/{{result.draw.country.toLowerCase()}}.svg'
                      alt='{{result.draw.country}}'
                      height='28'
                      width='28'
                      class='border'
                      loading='lazy' />
                  }
                </div>
                {{ result.name }}
                <img loading='lazy' alt='' src='/assets/images/svg/arrow-2.svg' height='12' width='8' />
              </a>
            }
          }
        </div>
      } @else if (result) {
        @if (result.events?.length) {
          <div>
            <div class='group-header'>{{ "Events" | translate }}</div>
            @for (sport of result.events; track trackBySport(sport)) {
              <div class='sport-header'>
                <img loading='lazy' alt='' src='{{sport.id | sportImage : false:  true}}' height='16' width='16' />
                <span>{{ sport.events[0].sport.name }}</span>
              </div>
              @for (event of sport.events; track event.id; let i = $index) {
                <div class='events-item'>
                  <scp-sports-event-row [event]='event'
                                        [tournament]='i === 0  || sport.events[i - 1].tournament.id !== event.tournament.id'
                                        [isBrowser]='isBrowser'
                                        [mini]='true'
                                        [search]='true'
                                        [source]='"Search"'
                                        (click)='sendDataToAnalytics("event")'>
                  </scp-sports-event-row>
                </div>
              }
            }
          </div>
        }
        @if (result.tournaments?.length) {
          <div>
            <div class='group-header'>{{ "Tournaments" | translate }}</div>
            @for (sport of result.tournaments; track trackBySport(sport)) {
              <div class='sport-header'>
                <img loading='lazy' alt='' src='{{sport.id | sportImage : false:  true}}' height='16' width='16' />
                <span>{{ sport.name }}</span>
              </div>
              <div>
                @for (item of sport.tournaments; track trackByTournament(item)) {
                  <a class='sport-tournament-item'
                     [routerLink]='"/sports/" + (item.tournament.live ? "live" :"prematch") +"/" + sport.slug + "/" + item.tournament.slug'
                     (click)='sendDataToAnalytics("tournament")'>
                    <scp-sports-tournament-icon [sport]='sport'
                                                [category]='item.category'
                                                [tournament]='item.tournament'
                                                [title]='false'>
                    </scp-sports-tournament-icon>
                    <div>{{ item.category.name + ". " + item.tournament.name }}</div>
                    <img loading='lazy' alt='' src='/assets/images/svg/arrow-2.svg' height='12' width='8' />
                  </a>
                }
              </div>
            }
          </div>
        }
        @if (result.sports?.length) {
          <div>
            <div class='group-header'>{{ "Sports" | translate }}</div>
            @for (sport of result.sports; track trackBySport(sport)) {
              <a class='sport-item'
                 [routerLink]='"/sports/" + (sport.liveCount > 0 ? "live" : "prematch") + "/" + sport.slug'
                 (click)='sendDataToAnalytics("sport")'>
                <img loading='lazy' alt='' src='{{sport.id | sportImage : false:  true}}' height='16' width='16' />
                <div>{{ sport.name }}</div>
                <img loading='lazy' alt='' src='/assets/images/svg/arrow-2.svg' height='12' width='8' />
              </a>
            }
          </div>
        }
      }
    }
  </div>
</ng-template>
