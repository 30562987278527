/* istanbul ignore file */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DOCUMENT, NgStyle } from '@angular/common';
import {
  CdkConnectedOverlay,
  ConnectedPosition,
  OverlayModule,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ESCAPE } from '@angular/cdk/keycodes';
import { SportImagePipe } from '@scpc/modules/sports/pipes/sport-image';
import { ActivatedRoute, NavigationStart, Router, RouterLink } from '@angular/router';
import { TournamentIconComponent } from '@scpc/modules/sports/components/tournament-icon/tournament-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, delay, filter, first, switchMap } from 'rxjs/operators';
import { fromEvent, Observable, of } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  EventCategory,
  EventSport,
  EventTournament,
  Event,
  SearchResult,
  FullSport,
} from '@scpc/modules/sports/dto';
import { EventRowComponent } from '@scpc/modules/sports/components/event-row/event-row.component';
import { SportsWebsocketService } from '@scpc/modules/sports/services/sports.websocket.service';
import { SportsService } from '@scpc/modules/sports/services/sports.service';
import { ScpService } from '@scpc/modules/common/services';
import { SearchResultItem } from '@scpc/dto/search.result';
import { FormatGameUrlPipe } from '@scpc/modules/games-lobby/pipes/format-game-url.pipe';

@Component({
  selector: 'scp-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle,
    TranslateModule,
    OverlayModule,
    RouterLink,
    TournamentIconComponent,
    SportImagePipe,
    ReactiveFormsModule,
    EventRowComponent,
    FormatGameUrlPipe,
  ],
  animations: [
    trigger('inputInOut', [
      state('in', style({
        opacity: '1',
        width: '100%',
      })),
      state('out', style({
        opacity: '0',
        width: '0',
      })),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out')),
    ]),
  ],
})
export class SearchComponent implements AfterViewInit, OnDestroy {

  @ViewChild('originRef', { static: true })
  protected originRef: ElementRef<HTMLInputElement>;

  @ViewChild('overlayRef', { read: CdkConnectedOverlay, static: false })
  protected overlayRef: CdkConnectedOverlay;

  @ViewChild('contentRef', { static: false })
  protected contentRef: ElementRef<HTMLDivElement>;

  @ViewChild('inputRef', { static: false })
  protected inputRef: ElementRef<HTMLInputElement>;

  @ViewChildren(EventRowComponent)
  private rowsComponents: QueryList<EventRowComponent>;

  @Input()
  public isBrowser: boolean;

  @Input()
  public product: string = 'sp';

  public searchControl: FormControl<string> = new FormControl<string>('');

  protected clear: boolean;
  protected visible: boolean;
  protected width: number;

  protected result: SearchResult | null;
  protected resultsItems: SearchResultItem[];
  protected less: boolean;
  protected loading: boolean;
  protected old: string = '';
  protected oldNormalized: string = '';

  protected position: ConnectedPosition[] = [{
    originY: 'bottom',
    originX: 'start',
    overlayX: 'start',
    overlayY: 'top',
  } as ConnectedPosition];

  constructor(protected readonly activatedRoute: ActivatedRoute,
              private readonly elementRef: ElementRef,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly destroyRef: DestroyRef,
              private readonly viewportRuler: ViewportRuler,
              @Inject(DOCUMENT) private readonly document: Document,
              private readonly sportsService: SportsService,
              private readonly sportsWebsocketService: SportsWebsocketService,
              private readonly zone: NgZone,
              private readonly router: Router,
              private readonly scpService: ScpService,
              private readonly googleTagManagerService: GoogleTagManagerService) {
  }

  @HostListener('window:mousedown', ['$event'])
  protected onClick(event: MouseEvent): void {
    if (this.visible) {
      const withinBoundaries: boolean = event.composedPath().includes(this.elementRef.nativeElement) ||
        (this.contentRef ? event.composedPath().includes(this.contentRef.nativeElement) : false);
      if (!withinBoundaries) {
        this.hide();
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  protected keyEvent(event: KeyboardEvent): void {
    if (event.keyCode == ESCAPE) {
      this.hide();
    }
  }

  public ngAfterViewInit(): void {
    this.processViewportChanges();
    this.processSearchQueries();
    this.processNavigations();
    if (this.isSport()) {
      if (this.sportsWebsocketService.isConnected()) {
        this.sportsWebsocketService.on('full_event', this.processUpdates);
      } else {
        this.sportsWebsocketService.onConnect()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => this.sportsWebsocketService.on('full_event', this.processUpdates));
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.isSport()) {
      this.sportsWebsocketService.off('full_event', this.processUpdates);
    }
  }

  protected show(): void {
    if (this.visible) {
      this.hide();
    } else {
      this.visible = true;
      this.changeDetectorRef.detectChanges();
      this.inputRef.nativeElement.focus();
      this.googleTagManagerService.event(this.product, 'search_opened');
    }
  }

  protected hide(): void {
    if (this.clear) {
      this.googleTagManagerService.event(this.product, 'search_hidden');
    }
    this.hideResults();
  }

  protected sendDataToAnalytics(event: string, close: boolean = true): void {
    this.googleTagManagerService.event(this.product, `search_${event}_selected`);
    if (close) {
      this.hideResults();
      this.updateSubscriptions(false);
    }
  }

  protected trackBySport(sport: FullSport | EventSport): string {
    return sport.id + (sport.liveCount > 0);
  }

  protected trackByTournament(item: {
    tournament: EventTournament,
    category: EventCategory
  }): string {
    return item.category.id + item.tournament.id + item.tournament.live;
  }

  protected onScroll($event?: TouchEvent): void {
    if (!this.loading) {
      this.inputRef.nativeElement.blur();
      if ($event) {
        const el: HTMLDivElement = this.contentRef.nativeElement;
        if (el.scrollHeight <= el.clientHeight) {
          $event.stopPropagation();
          $event.preventDefault();
        }
      }
    }
  }

  private hideResults(): void {
    this.searchControl.setValue('');
    this.old = '';
    this.oldNormalized = '';
    this.visible = false;
    this.clear = false;
    this.result = null;
  }

  private processNavigations(): void {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef), filter(e => e instanceof NavigationStart))
      .subscribe(() => this.hide());
  }

  private processViewportChanges(): void {
    this.viewportRuler.change()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
        if (this.visible) {
          if (this.isBrowser) {
            this.width = this.originRef.nativeElement.getBoundingClientRect().width;
          }
          this.changeDetectorRef.detectChanges();
          of(true)
            .pipe(takeUntilDestroyed(this.destroyRef), delay(200), first())
            .subscribe((): void => {
              this.changeDetectorRef.detectChanges();
              this.overlayRef?.overlayRef?.updatePosition();
            });
        }
      });
    this.hideOnScroll(this.document.getElementsByClassName('scp-scrollbar-content')[0]);
  }

  private hideOnScroll(element: Element | Window): void {
    if (element) {
      fromEvent(element, 'scroll')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((): void => {
          this.hide();
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  private processSearchQueries(): void {
    this.searchControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((value: string): Observable<string> => {
        value = this.normalize(value);
        this.clear = !!value;
        if (this.isBrowser) {
          this.width = this.originRef.nativeElement.getBoundingClientRect().width;
        }
        if (value !== this.oldNormalized) {
          this.less = value.length < 3;
          this.loading = !this.less;
          if (this.isSport()) {
            this.updateSubscriptions(false);
            this.result = null;
          } else {
            this.resultsItems = null;
          }
          this.changeDetectorRef.markForCheck();
        }
        return of(value);
      }),
      debounceTime(500),
      switchMap((value: string): Observable<SearchResult | SearchResultItem[] | null> => {
        if (value !== this.oldNormalized) {
          if (this.less) {
            return of(null);
          }
          this.old = value;
          this.oldNormalized = this.normalize(this.old);
          if (this.isSport()) {
            return this.sportsService.search(this.old);
          } else {
            return this.scpService.search(this.product.toUpperCase().replace('-', '_'), this.old);
          }
        }
        return this.isSport() ? of(this.result) : of(this.resultsItems);
      })).subscribe((result: SearchResult | SearchResultItem[] | null): void => {
      if (this.isSport()) {
        this.result = result as SearchResult;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
        this.updateSubscriptions(true);
      } else {
        this.resultsItems = result as SearchResultItem[];
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  private normalize(value: string): string {
    return value.split(' ').filter((part: string): boolean => !!part).join(' ').trim();
  }

  private updateSubscriptions(subscribe: boolean): void {
    const eventsIds: string[] = this.getEventsIds().map((id: string): string => 'f_e_' + id);
    if (eventsIds.length) {
      if (subscribe) {
        this.sportsWebsocketService.subscribe(eventsIds);
      } else {
        this.sportsWebsocketService.unsubscribe(eventsIds);
      }
    }
  }

  private getEventsIds(): string[] {
    if (this.result) {
      return [...new Set<string>(this.result.events?.flatMap(s => s.events).map((s: Event): string => s.id))];
    }
    return [];
  }

  private processUpdates = (data: string): void => {
    this.processEventsUpdates(data);
  };

  private processEventsUpdates(data: string): void {
    this.zone.runOutsideAngular((): void => {
      if (this.result?.events?.length) {
        const event: Event = JSON.parse(data);
        let found: boolean = false;
        for (const sport of this.result.events) {
          for (let i = 0; i < sport.events.length; i++) {
            if (sport.events[i].id === event.id) {
              sport.events[i] = event;
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
        if (found) {
          this.rowsComponents.find(item => item.event.id === event.id)?.update();
        }
      }
    });
  }

  private isSport(): boolean {
    return this.product === 'sp';
  }

}
